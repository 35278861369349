


<template>
  <div class="container">
    <div style="width: 100%; padding-left: 10rem"></div>
    <div class="row">
      <div class="col-md-5 cl">
        <div class="main bg-2">
          <div class="content">
            <div class="description">
              <img src="@/assets/Notfound.jpg" height="500" width="500" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 cl">
        <div style="padding-top: 17rem; padding-left: 3rem">
          <b-button variant="danger" @click="goBack"> Home </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    goBack() {
    this.$router.push("/");
    },
  },
};
</script>
<style scoped>
</style>


